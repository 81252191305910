<template>
  <el-drawer v-model="drawer" :close-on-click-modal="false" size="850px">
    <template #header>
      <div class="header_top">
        <div>接收物品</div>
        <div>
          <el-button v-if="showSaveVisible === '1'" type="primary" @click="save" size="small"
            >保存</el-button
          >
        </div>
      </div>
    </template>
    <div v-if="!form.id">
      <el-alert
        title="请填完所有带 * 号的必填项再提交"
        type="warning"
        show-icon
      />
    </div>
    <el-form
      :model="form"
      label-width="90px"
      :rules="rules"
      size="small"
      ref="ruleFormRef"
      :inline="true"
    >
      <div class="record">基本信息：</div>
      <el-form-item label="客户名称" prop="customerId">
        <select-common
          placeholder="请选择客户"
          v-model:modelValue="form.customerId"
          :options="customerOptions"
          valueKey="id"
          labelKey="customerName"
          class="w280 margin0"
        />
      </el-form-item>
      <el-form-item label="交接状态">
        <div class="w280">{{ form.id ? "已接收" : "待提交" }}</div>
      </el-form-item>
      <el-form-item label="接收日期" prop="date">
        <el-date-picker
          v-model="form.date"
          type="date"
          placeholder="请选择接收日期"
          value-format="YYYY-MM-DD"
          style="width: 280px"
        />
      </el-form-item>
      <el-form-item label="接收编号" prop="number">
        <el-input
          v-model="form.number"
          placeholder="请输入接收编号"
          class="w280"
        ></el-input>
      </el-form-item>
      <el-form-item label="创建人" prop="cnName">
        <div class="w280">
          {{ cnName }}
        </div>
      </el-form-item>
      <el-form-item label="接收人" prop="receiveName">
        <select-common
          placeholder="请选择接收人"
          v-model:modelValue="form.receiveName"
          :options="props.userOptions"
          valueKey="CnName"
          labelKey="CnName"
          class="w280 margin0"
        />
      </el-form-item>
      <el-form-item label="移交人" prop="transferName">
        <el-input
          v-model="form.transferName"
          placeholder="请输入移交人"
          class="w280"
        ></el-input>
      </el-form-item>
      <el-form-item label="移交人电话" prop="transferTel">
        <el-input
          v-model="form.transferTel"
          placeholder="请输入移交人电话"
          class="w280"
        ></el-input>
      </el-form-item>
      <el-form-item label="快递单号" prop="courierNumber">
        <el-input
          v-model="form.courierNumber"
          placeholder="请输入快递单号"
          class="w280"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="record">录入物品：</div>
    <el-table :data="form.children" style="width: 100%" border>
      <el-table-column type="index" label="序号" width="55" align="center" />
      <el-table-column width="150">
        <template #header> <span class="red">*</span>物品名称 </template>
        <template #default="{ row }">
          <cascader-common
            v-model:modelValue="row.goodsName"
            placeholder="请选择物品名称"
            :options="props.typeOptions"
            :casProps="casProps"
            v-model:first="row.goodsType"
          />
        </template>
      </el-table-column>
      <el-table-column label="物品类型" width="80" prop="goodsType">
      </el-table-column>
      <el-table-column prop="goodsNum" label="数量">
        <template #default="{ row }">
          <el-input-number
            v-model="row.goodsNum"
            :min="1"
            size="small"
            class="w80"
          /> </template
      ></el-table-column>
      <el-table-column prop="goodsUnit" label="单位">
        <template #default="{ row }">
          <el-input v-model="row.goodsUnit" size="small"></el-input> </template
      ></el-table-column>
      <el-table-column prop="remark" label="备注">
        <template #default="{ row }">
          <el-input v-model="row.remark" size="small"></el-input> </template
      ></el-table-column>
      <el-table-column prop="storageLocation">
        <template #header> <span class="red">*</span>存放位置 </template>
        <template #default="{ row }">
          <el-input
            v-model="row.storageLocation"
            size="small"
          ></el-input> </template
      ></el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button size="small" link @click="addGoods(form.children)"
            >新增</el-button
          >
          <el-button
            size="small"
            link
            class="del_btn"
            @click="delGoods(form.children, scope.$index)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="record">接收资料：</div>
    <FileUploader @change="updateFileList" :initialFileList="form.fileList" />
    <div class="record">其他说明：</div>
    <el-input
      v-model="form.explain"
      maxlength="200"
      style="width: 810px"
      placeholder="请输入说明"
      show-word-limit
      type="textarea"
      :autosize="{ minRows: 4, maxRows: 8 }"
    />
  </el-drawer>
</template>

<script setup>
import { ref, getCurrentInstance, defineProps, defineExpose } from "vue";
import { customerList, goodsCodeList } from "@/api/newCrm";
import { ElMessage } from "element-plus";
import { getCurrentDate } from "@/utils/fun";
import FileUploader from "@/components/uploadImg/FileUploader.vue";
import { saveGoods } from "@/api/newCrm";
import { nextTick } from "vue";
const { proxy } = getCurrentInstance();
const emit = defineEmits();
const props = defineProps({
  typeOptions: {
    type: Array,
  },
  userOptions: {
    type: Array,
  },
});
const drawer = ref(false);
const customerOptions = ref([]);
const showSaveVisible = ref('1')
const cnName = proxy.$store.getters["user/user"].cnName;
const form = ref({
  handoverStatus: "已接收",
  fileList: [],
  date: getCurrentDate(),
  type: "0",
  children: [
    {
      goodsNum: 1,
    },
  ],
});
const rules = ref({
  customerId: [{ required: true, message: "请选择客户", trigger: "change" }],
  date: [{ required: true, message: "请选择接收日期", trigger: "change" }],
  number: [{ required: true, message: "请输入接收编号", trigger: "blur" }],
  receiveName: [{ required: true, message: "请选择接收人", trigger: "change" }],
  transferName: [{ required: true, message: "请输入移交人", trigger: "blur" }],
});
const ruleFormRef = ref();
const casProps = ref({
  expandTrigger: "hover",
  label: "label",
  value: "label",
});
const init = (row, showSaveBtn) => {
  showSaveVisible.value = showSaveBtn
  reset();
  if (row) {
    form.value = Object.assign({}, row);
    form.value.children = [row];
    nextTick(()=>{
      let arr = form.value.file ? form.value.file.split(","):[]
      form.value.fileList = arr.map(v=>{
        const ext = v.split(".").pop();
        return {
          name:v.split("/").pop(),
          url:v,
          ext: ext,
        }
      });
    },200)
  } else {
    getInfo();
  }
  drawer.value = true;
  getList();
};
const getInfo = () => {
  goodsCodeList({ type: 1 }).then((res) => {
    if (res.data.msg == "success") {
      form.value.number = res.data.data;
    }
  });
};
const getList = () => {
  customerList({ customerType: "2" }).then((res) => {
    if (res.data.msg == "success") {
      customerOptions.value = res.data.data.list;
    }
  });
};
const addGoods = (list) => {
  list.push({ goodsNum: 1 });
};
const delGoods = (list, index) => {
  if (list.length <= 1) {
    proxy.$message.error("仅剩一项禁止删除");
    return;
  }
  list.splice(index, 1);
};
const updateFileList = (e) => {
  form.value.fileList = e;
};
const save = () => {
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      let fail = form.value.children.find(
        (v) => !v.goodsName || !v.storageLocation
      );
      if (fail) {
        ElMessage.warning(
          "存在物品未选择商品名称或未填写存放位置，请选择后重试"
        );
        return;
      }
      let arr = [];
      arr = form.value.fileList.map((v) => {
        return v.url;
      });
      form.value.file = arr.join(",");
      saveGoods(form.value).then((res) => {
        if (res.data.msg == "success") {
          ElMessage.success("保存成功");
          drawer.value = false;
          emit("success");
        }
      });
    }
  });
};
const reset = () => {
  form.value = {
    handoverStatus: "已接收",
    fileList: [],
    date: getCurrentDate(),
    type: "0",
    children: [
      {
        goodsNum: 1,
      },
    ],
  };
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.header_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.record {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin: 10px 0;
}
.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.w280 {
  width: 280px !important;
}
.margin0 {
  margin: 0 !important;
}
.w80 {
  width: 80px !important;
}
.red {
  color: #f00;
  margin-right: 3px;
  font-size: 15px;
}
.del_btn {
  margin-left: 0;
  color: #fe696a;
}
:deep(.el-upload-dragger) {
  padding: 2px 10px;
}
</style>
