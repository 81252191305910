<template>
  <div class="app-container">
    <section class="top_btns clearfix">
      <div style="float:left">
        <span style="font-size:13px">凭证状态：</span>
        <el-select size="small" v-model="listQuery.voucher" @change="getList" style="margin:0;width:100px">
          <el-option label="全部" :value="9"></el-option>
          <el-option label="已生成凭证" :value="1"></el-option>
          <el-option label="未生成凭证" :value="2"></el-option>
        </el-select>
      </div>
      <div class="piliang-container">
        <span style="color:#F15A24;font-size:13px;margin-right: 10px;">余额:{{cashAmount}}</span>
        <el-dropdown split-button type="primary" @click="handleAdd" size="small" style="margin-right:10px" :hide-on-click="false" v-if="$buttonStatus('zj_xj_tj')">
          <i class="iconfont icon-jiahao"></i> 添加
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <span size="small" @click="openCom">导入</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <qzf-button button_code="zj_xj_sc" plain size="small" type="danger" @success="delAll()" :loading="deleteLoding">
          <el-icon><Delete /></el-icon><span  > 删除</span>
        </qzf-button>
        <qzf-button button_code="zj_xj_bc" size='small' type="success" @success="saveAll"   >
          <el-icon><Finished /></el-icon><span  > 批量保存</span>
        </qzf-button>
        <el-dropdown @command="handleCommand" size="small" style="margin-left:10px" v-if="$buttonStatus('zj_xj_yjscpz')">
          <el-button size="small" type="primary">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="a">
                <span>一键生成凭证</span>
              </el-dropdown-item>
              <el-dropdown-item command="b">
                <span>导出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </section>

    <el-table stripe :height="contentStyleObj" @selection-change="handleSelectionChange" :data="list" style="width: 100%" :show-header='true' border fit highlight-current-row :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" v-loading="loadingBig">
      
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="40" align="center" style="padding-left:0;" />
      <el-table-column label="日期" align="center" width="150">
        <template #default="scope">
            <el-date-picker style="width: 100%" :disabled="!scope.row.edit" v-model="scope.row.transDate" type="date" placeholder="选择日期" size="small"></el-date-picker>
        </template>
      </el-table-column>
      <el-table-column label="方向" align="center" min-width="150">
        <template #default="scope">
          <span style="padding-left:10px; color:green" v-if="scope.row.type == 1">收入</span>
          <span style="padding-left:10px; color:red" v-if="scope.row.type == 2">支出</span>
        </template>
      </el-table-column>
      <el-table-column label='业务类型' align="center" min-width="320">
        <template #default="scope">
          <div class="block">
            <business-type v-model:businessId="scope.row.businessTypeId" v-model:itemId="scope.row.subjectId" v-model:fzhsId="scope.row.fzhsId" v-model:szbz="scope.row.type" v-model:businessTypeName="scope.row.businessTypeName" type="cash" :disabledIn="!scope.row.edit"></business-type>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="金额" align="center" min-width="150">
        <template #default="scope">
          <qzf-input :disabled="!scope.row.edit"  v-model:num="scope.row.amount" size="small" />
        </template>
      </el-table-column>
      <el-table-column label="凭证" align="center" min-width="150">
        <template #default="scope">
          <span v-if="scope.row.VoucherNo" @click="getHandlePingzheng(scope.row.voucherId)" style="color:#39b0d2;text-decoration:underline;cursor:pointer">{{ scope.row.VoucherNo }}</span>
          <span v-else-if="$buttonStatus('zj_xj_yjscpz')" @click="showVoucher(scope.row)" style="color:#39b0d2;text-decoration:underline;cursor:pointer">凭证预览</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="top_right_btns" min-width="200">
        <template #default="scope">
          <el-tooltip content="保存" placement="top" v-if="$buttonStatus('zj_xj_bc')" effect="dark">
            <span>
              <i class="iconfont icon-baocun" @click="handleSave(scope.row)" v-if="scope.row.edit"></i>
            </span>
          </el-tooltip>
          <el-tooltip content="编辑" placement="top" v-if="$buttonStatus('zj_xj_bj')" effect="dark">
            <span>
              <i class="iconfont icon-bianji" @click="handleEdit(scope.row)" v-if="!scope.row.edit"></i>
            </span>
          </el-tooltip>
          <el-tooltip content="删除" placement="top" v-if="$buttonStatus('zj_xj_sc')" effect="dark">
            <span>
              <i class="iconfont icon-shanchu" @click="handleDelete(scope.row,scope.$index)"></i>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
    <voucher-show ref="voucherShow" from="4" @success="getList"></voucher-show>
  </div>
  <qzf-import-back :hideButton="true" ref="importBck" @success="getList()" from="现金"></qzf-import-back>
</template>

<script>
import { saveCash , delCash , cashList } from "@/api/cash"
import { getPeriodTime } from "@/utils/fun";
import { oneKeyVoucher } from '@/api/voucher'
import { exportCash } from '@/api/export'
export default {
  name:'cash',
  data() {
    return {
      cashAmount: 0,
      list: [], // 现金列表
      listQuery: {
        page: 1,
        limit: 20,
        voucher:9,
      },
      total:2,
      allDelSelect:[],
      allSaveSelect:[],
      loadingBig: false,
      comId: this.$store.getters['user/comInfo'].comId * 1,
    }
  },
  created() {
    this.getList()
    this.contentStyleObj = this.$getHeight(200)
  },
  methods:{
    handleAdd() {
      this.list.unshift({
        amount: 0,
        transDate: getPeriodTime(),
        edit: true,
        remark: "转账摘要(备注)"
      });
    },
    handleSave(item){
      if(this.$checkSettleStatus()) return
      saveCash([item]).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.$bus.emit('voucherUpdate')
          item.edit = false
          this.getList()
        }
      })
    },
    handleEdit(item) {
      if(this.$checkSettleStatus()) return
      item.edit = true
    },
    handleDelete(item, index) {
      if(this.$checkSettleStatus()) return
      if(!item.id){
        this.list.splice(index, 1);
        return
      }
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delCash([{ id: item.id }]).then(res => {
          if(res.data.msg == 'success'){
            this.$qzfMessage("删除成功")
            this.$bus.emit('voucherUpdate')
            this.getList();
          }
        });
      })
    },
    getList(){
      this.loadingBig = true
      cashList(this.listQuery).then(res=>{
        this.loadingBig = false
        if(res.data.msg == 'success'){
          this.list = res.data.data.list ? res.data.data.list : []
          this.total = res.data.data.total
          this.cashAmount = res.data.data.endAmount
          this.list.map(item => {
            // 每一小条
            if (!item.businessTypeId) {
              item.edit = true;
            }
          });
        }
      })
    },
    handleSelectionChange(val) {
      //多选选完之后在这
      let delArr = [];
      val.map(v => {
        delArr.push({id:v.id});
      });
      this.allDelSelect = delArr;
      this.allSaveSelect = val
    },
    delAll(){
      if(this.allDelSelect.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.$confirm(`确认删除${this.allDelSelect.length}条票据么? `, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loadingBig = true
        delCash(this.allDelSelect).then(res => {
          this.loadingBig = false
          if(res.data.msg != 'success'){
            return
          }
          this.$qzfMessage("删除成功")
          this.$bus.emit('voucherUpdate')
          this.getList();
        });
      })
    },
    saveAll(){
      if(this.allSaveSelect.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      saveCash(this.allSaveSelect).then(res=>{
        if(res.data.msg == "success"){
          this.allSaveSelect.map(v=>{
            v.edit = false
          })
          this.$qzfMessage("保存成功")
          this.$bus.emit('voucherUpdate')
          this.getList();
        }
      })
    },
    showVoucher(item){
      this.$refs.voucherShow.getList(item)
    },
    getHandlePingzheng(voucherId) {
      this.$store.dispatch('commons/getSubjects', this.comId)
      this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
      this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher"
      });
    },
    handleCommand(command) {
      if(command == 'a'){
        if(this.$checkSettleStatus()) return
        if(this.allDelSelect.length == 0){
          this.$qzfMessage("请选择至少一条数据",1)
          return
        }
        let invoiceArray = []
        this.allDelSelect.map(v => {
          invoiceArray.push(v.id)
        })
        this.loadingBig = true
        oneKeyVoucher({type:'cash',ids:invoiceArray}).then(res=>{
          if(res.data.msg == "success"){
          this.loadingBig = false
            this.$qzfMessage("操作成功")
            this.$bus.emit('voucherUpdate')
            this.getList()
          }
        })
      }else if(command == 'b'){
        let ids = []
        this.allDelSelect.map(v => {
          ids.push(v.id)
        })
        let param = {
          voucher:this.listQuery.voucher,
          ids:ids
        }
        exportCash(param).then(res=>{
          if(res.data.msg == 'success'){
            window.open(res.data.data.url)
          }
        })
      }
    },
    //导入
    openCom(){
      if(this.$checkSettleStatus()) return
      this.$refs.importBck.handImport()
    },
  }
}
</script>

<style lang="scss" scoped>
.piliang-container {
  float: right;
}
.top_right_btns {
  float: right;
  margin-right: 15px;
  i {
    margin: 0 5px;
    cursor: pointer;
    color: var(--themeColor,#17a2b8);
    font-size: 18px;
    line-height: 24px;
  }
}
.top_btns{
  margin-bottom: 10px;
}
</style>