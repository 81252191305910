<template>
  <div class="top_select">
    <div>
      <el-button icon="RefreshRight" size="small" style="margin-right: 5px" @click="init()"></el-button>
      <el-input size="small" placeholder="请输入客户编码/客户名称/存放位置" v-model.trim="listQuery.customerName" style="width: 200px"
        @keyup.enter="init" clearable></el-input>
      <el-button size="small" type="primary" @click="init" style="margin-right: 10px" icon="Search">搜索</el-button>
      <!-- <search @success="init" @cancel="cancel">
        <el-form
          style="width: 550px"
          label-width="90px"
          size="small"
          class="style_form"
        >
          <el-form-item label="开始日期：">
            <el-date-picker
              style="width: 150px"
              v-model="listQuery.beginTime"
              type="date"
              placeholder="起始日期"
              clearable
              value-format="YYYY-MM-DD"
            />
            &nbsp;-&nbsp;
            <el-date-picker
              style="width: 150px"
              v-model="listQuery.endTime"
              type="date"
              placeholder="终止日期"
              clearable
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-form>
      </search> -->
    </div>
    <div>
      <el-button size="small" type="primary" @click="receive">接收物品</el-button>
      <el-button size="small" type="success" @click="transer">归还物品</el-button>
    </div>
  </div>
  <el-table :data="list" style="width: 100%" border :height="contentStyleObj" @selection-change="handleSelectionChange"
    v-loading="loading" row-key="customerId" :span-method="mergeRows">
    <template #empty>
      <el-empty :image-size="150" description="没有数据"></el-empty>
    </template>
    <el-table-column type="selection" width="55" align="center" :selectable="selectable" />
    <el-table-column prop="customerName" label="客户名称"> </el-table-column>
    <el-table-column prop="receiveName" label="保管人"> </el-table-column>
    <el-table-column prop="goodsType" label="物品类型"> </el-table-column>
    <el-table-column prop="goodsName" label="物品名称"> </el-table-column>
    <el-table-column prop="goodsNum" label="数量"> </el-table-column>
    <el-table-column prop="goodsUnit" label="单位"> </el-table-column>
    <el-table-column prop="storageLocation" label="存放位置"> </el-table-column>
    <el-table-column prop="remark" label="备注"> </el-table-column>
    <el-table-column prop="inventoryName" label="操作">
      <template #default="scope">
        <el-button link size="small" @click="checkInfo(scope.row)">详情</el-button>
      </template>
    </el-table-column>
  </el-table>

  <div class="page_container">
    <qzf-pagination :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
      @pagination="init" />
  </div>

  <goodsReceive ref="goodsReceiveRef" :typeOptions="props.typeOptions" :userOptions="userOptions" @success="init" />
  <goodsBack ref="goodsBackRef" :userOptions="props.userOptions" @success="init" />
</template>
<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { goodsList } from "@/api/newCrm";
import goodsReceive from "./goodsReceive.vue";
import { ElMessage } from "element-plus";
import goodsBack from "./goodsBack.vue";
import { nextTick } from "vue";
const { proxy } = getCurrentInstance();
const props = defineProps({
  userOptions: {
    type: Array,
  },
  typeOptions: {
    type: Array,
  },
});
const listQuery = ref({
  page: 1,
  limit: 20,
  delType: "0",
});
const list = ref([]);
const total = ref(0);
const loading = ref(false);
const contentStyleObj = ref({});
const options = ref([]);
const goodsReceiveRef = ref();
const sels = ref([]);
const goodsBackRef = ref();
const selectable = (row) => !row.goodsId;
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(260);
});

function cancel() {
  listQuery.value = {
    page: 1,
    limit: 20,
    delType: "0",
  }
  init()
}

const init = () => {
  loading.value = true;
  nextTick(()=>{
    goodsList(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
      total.value = res.data.data.total;
    }
  });
  },200)
};
const handleSelectionChange = (e) => {
  sels.value = e;
};
//查看详情
const checkInfo = (row) => {
  goodsReceiveRef.value.init(row, '1')
  // goodsInfo({ id: row.id }).then((res) => {});
};
const mergeRows = ({ row, column, rowIndex, columnIndex }) => {
  if (columnIndex === 1) {
    const prevRow = list.value[rowIndex - 1];
    if (prevRow && row.customerId === prevRow.customerId) {
      return { rowspan: 0, colspan: 1 };
    } else {
      let rowspan = 1;
      for (let i = rowIndex + 1; i < list.value.length; i++) {
        if (list.value[i].customerId === row.customerId) {
          rowspan++;
        } else {
          break;
        }
      }
      return { rowspan, colspan: 1 };
    }
  }
};
const receive = () => {
  goodsReceiveRef.value.init(undefined, '1');
};
//归还物品
const transer = () => {
  if (sels.value.length == 0) {
    ElMessage.warning("请选择要归还的物品");
    return;
  }
  let fail = sels.value.find((v) => v.customerId != sels.value[0].customerId);
  if (fail) {
    ElMessage.warning("请选择同一客户归还");
    return;
  }
  goodsBackRef.value.init(sels.value, "提交");
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.page_container {
  float: right;
  margin-top: 10px;
}

.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
