<template>
  <div style="position: relative;" v-loading="loading1">
    <div style="position:absolute;top:10px;z-index: 99;right: 13px;">
      <span style="color:#39b0d2;font-size: 13px;">
        <span style="margin-right:10px">数量:{{total}}笔</span>
        <span style="margin-right:10px">本期收入:{{bankAmountIn}}元</span>
        <span style="margin-right:10px">本期支出:{{bankAmountOut}}元</span>
        <span style="margin-right:10px">余额:{{bankAmount}}元</span>
        <span style="margin-right:10px">银行余额:{{bankEndAmount}}元</span>
      </span>
      <qzf-video vid="63dd60f5881b026fb5c5d664fdf5b6e3_6"></qzf-video>
    </div>
    <div class="hint">
     <i class="iconfont icon-gantanhao1" style="font-size: 13px"></i
        >温馨提示：优先补充发票，银行补充更便捷
    </div>
    <div class="check" v-if="info.sj && info.amount" @click="checkAmount">
      查看余额
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">

      <el-tab-pane label="全部" name="first"></el-tab-pane>
      <el-tab-pane label="未补充" name="second"></el-tab-pane>
      <el-tab-pane label="已补充" name="third"></el-tab-pane>
    
      <!-- 顶部搜索区域和按钮 -->
      <div class="top_btns">
        <div class="left_box">
          <span style="color:#212529;">银行账户：</span>
          <el-select placeholder="请选择" v-model="bankNameId" @change='changeBank' size="small" filterable style="width:120px;color:#212529;">
            <el-option v-for="(item,index) in bankList " :key="index" :label="item.name" :value="item.id" style="color:#212529;"></el-option>
          </el-select>
          <el-select placeholder="请选择收支类型" size="small" @change='getList' v-model="listQuery.typeAmount" style="width:120px;margin: 0px;">
            <el-option label="收入" :value="1"></el-option>
            <el-option label="支出" :value="2"></el-option>
          </el-select>
          <search @success="getList" @cancel="cancel">
            <el-form style="width:675px;" class="styleForm" label-width="100px" :inline="true">
              <div>
                <el-form-item label=" 账户分类：" >          
                  <span style="margin-left:10px;font-size: 13px;">{{ accountStatus }}账户 </span>
                </el-form-item>
              </div>
              <el-form-item label="科目:" >
                <subject-list :addNotShow="true" style="width:200px" v-model:subjectId="listQuery.subjectId" v-model:fzhsItemId="listQuery.fzhsId"></subject-list>
              </el-form-item>
              <el-form-item label="对方账户名:" >
                <el-input style="width:200px" size="small" placeholder="对方账户名" v-model="listQuery.otherName" clearable></el-input>
              </el-form-item>
              <el-form-item label="凭证状态:" >
                <el-select placeholder="请选择" size="small" v-model="listQuery.pzzt" style="width:200px;margin: 0px;">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option label="已生成" :value="1"></el-option>
                  <el-option label="未生成" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="交易时间:" >
                <el-date-picker
                  style="width:92px;"
                  size="small"
                  v-model="listQuery.beginTime"
                  type="date"
                  placeholder="起始日期"
                  clearable
                  value-format="YYYY-MM-DD"
                /> &nbsp;-&nbsp;
                <el-date-picker
                  style="width:92px;"
                  size="small"
                  v-model="listQuery.endTime"
                  type="date"
                  placeholder="终止日期"
                  clearable
                  value-format="YYYY-MM-DD"
                />
              </el-form-item>
              <el-form-item label="金额:">
                <el-input style="width:92px" size="small" placeholder="金额起" v-model="listQuery.beginAmount" clearable></el-input>
                &nbsp;-&nbsp;
                <el-input style="width:92px" size="small" placeholder="金额止" v-model="listQuery.endAmount" clearable></el-input>
              </el-form-item>
              <el-form-item label="凭证号：">
                <el-input v-model="listQuery.voucherNo" placeholder="请输入凭证号" size="small" style="width:200px"></el-input>
              </el-form-item>
              <el-form-item label="摘要:">
                <el-input style="width:200px" size="small" placeholder="摘要" v-model="listQuery.summary" clearable></el-input>
              </el-form-item>
              <el-form-item label="类型：">
                <el-select placeholder="请选择类型" size="small" v-model="listQuery.otherNameType" style="width:200px;margin: 0px;">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="对公" value="对公"></el-option>
                  <el-option label="个人" value="个人"></el-option>
                  <el-option label="代扣" value="代扣"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="备注：">
                <el-input v-model="listQuery.bz" placeholder="请输入备注" size="small" style="width:200px"></el-input>
              </el-form-item>
            </el-form>
          </search>
          <span v-if="this.assignXybh && this.bankNameId"> <el-button type="text" style="margin-left: 15px;color: #67C23A;">已授权</el-button> </span>
          <span v-else-if="this.bankNameId"><el-button type="text" style="margin-left: 15px;" @click="showAgreement" :disabled="!$buttonStatus('zj_yh_qsq')">去授权</el-button></span>
         
        </div>
        <div class="right_box">
          <qzf-video vid="63dd60f588b9671cb1c7459160cc1ed7_6"></qzf-video>

          <span v-if="autoBcStatus"
            style="
              font-size: 13px;
              color:#e6a23c;
              margin-right: 5px;
            "
            ><i class="iconfont icon-sanjiaotanhao" style="color:#e6a23c;margin-right:4px"></i>{{this.autoBcType}}自动补充中！</span
          >
        <el-button v-if="$buttonStatus('fp_zj_ai_bc')" :disabled="autoBcStatus" :loading="this.buttonLoading" type="success" size="small" @click="bankSetting" style="margin-right:5px"><img style="margin-left:-7px;margin-top:2px;width:21px;height:14px;margin-right:3px" src="@/assets/ai3d.png" alt=""> 自动补充</el-button>
          <el-dropdown split-button type="primary" @click="handleAddBig" size="small" style="margin-right:10px" :hide-on-click="false" v-if="$buttonStatus('zj_yh_tj')">
            <i class="iconfont icon-jiahao"></i> 添加
            <template #dropdown>
              <el-dropdown-menu class="xxx">
                <el-dropdown-item>
                  <span size="small" @click="openCom">银行导入</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span size="small" @click="openComPdf">导入回单</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <qzf-button size='small' type="primary" @success="handleCzmuch" button_code="zj_yh_plbc" :disabled="autoBcStatus">
            <el-icon><Memo /></el-icon><span  > 批量补充</span>
          </qzf-button>
          <qzf-button size="small" plain type="danger" @success="handleDeleteAll()" button_code="zj_yh_plsc" >
            <el-icon><Delete /></el-icon><span  > 删除</span>
          </qzf-button>
          <qzf-button button_code="zj_yh_yjqc" size="small" type="success" @success="allSave">
            <el-icon><Finished /></el-icon><span  > 一键全存</span>
          </qzf-button>
          <el-dropdown size="small" style="margin-left:10px" >
            <el-button size="small" type="primary">
              更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="a" v-if="$buttonStatus('zj_yh_yjscpz')" @click="handleCommand">
                  <span>生成凭证</span>
                </el-dropdown-item>
                <el-dropdown-item command="c" v-if="$buttonStatus('zj_yh_yjscpz')" @click="clearInfo">
                  <span>清除补充信息</span>
                </el-dropdown-item>
                <el-dropdown-item command="d" @click="sort">
                  <span>流水排序</span>
                </el-dropdown-item>
                <!-- <el-dropdown-item command="e" @click="sort('sr')">
                  <span>先收入后支出排序</span>
                </el-dropdown-item>
                <el-dropdown-item command="f" @click="sort('sj')">
                  <span>创建时间升序排序</span>
                </el-dropdown-item> -->
                <el-dropdown-item command="b" @click="daochu" v-if="$buttonStatus('zj_yh_dc')">
                  <span>导出</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    
    <!-- 导入 -->

    <div class="table_height"  ref="item" style="margin-top:10px" >
      <section class="bank-table-header">
        <input v-if="this.list.length == 0" type="checkbox" :checked="undefined"/>
        <input v-else type="checkbox" @click="allSelect" :checked='isAllSelected'/>

        <div :style="{width:(accountStatus != '人民币' && accountStatus != '' && accountStatus ? '10%':'15%')}">日期
          <el-icon class="opera_icon" @click="sortChange"><DCaret /></el-icon>
        </div>
        <div :style="{width:(accountStatus != '人民币' && accountStatus != '' && accountStatus ? '25%':'30%')}">业务类型</div>

        <div style="width: 10%;" v-if="accountStatus != '人民币' && accountStatus != '' && accountStatus">原币金额</div>
        <div style="width: 10%;" v-if="accountStatus != '人民币' && accountStatus != '' && accountStatus">汇率</div>  

        <div style="width: 10%;">本位币金额</div>
        <div :style="{width:(accountStatus != '人民币' && accountStatus != '' && accountStatus ? '10%':'15%')}">摘要</div>
        <div :style="{width:(accountStatus != '人民币' && accountStatus != '' && accountStatus ? '15%':'20%')}">备注</div>
        <div style="width: 10%;">操作</div>
      </section>
        
        <!-- 一大条 -->
      <!-- <div class="table_height" @scroll="scrollEvent" :style="{height:contentStyleObj}" v-loading="isLoading"> -->
        <div v-loading="isLoading">
        <el-scrollbar :style="{height:contentStyleObj}">
        <el-empty :image-size="150" v-if="this.list.length == 0"></el-empty>

        <section class="bank-item-container" v-for="(item,index) in list" :key="index">
          <section class="bank-item-header">
            <div class="ccyc">
              <input type="checkbox" :checked="item.checked" @click="singleSelect(item, index)"/>
            </div>
            <div class="ccyc">
              {{ bankName }}
            </div>
            <el-tooltip :content="item.otherName" placement="top" effect="dark">
              <div class="ccyc" style="width:321px">
                对方账户名称:<input v-if="item.show || this.activeName == 'second'" type="text" class="dfyhm" v-model="item.otherName" style="width:220px">
                <span v-else>{{item.otherName}}</span>
              </div>
            </el-tooltip>
            <div style="color:red;width:18%" v-if="item.amount < 0" class="ccyc">
              支出金额(本位币): {{ String(item.amount).substring(1) }}
            </div>
            <div v-else style="width:18%" class="ccyc">
              收入金额(本位币): {{ item.amount }}
            </div>
            <div class="ccyc" style="width:123px">
              凭证号:
              <span style="color:var(--themeColor,#17a2b8);text-decoration:underline;cursor:pointer;font-weight:600" v-if="item.voucherNo" @click="getHandlePingzheng(item.voucherId)">{{item.voucherNo}}</span>
              <span style="color:var(--themeColor,#17a2b8);cursor:pointer" v-else-if="$buttonStatus('zj_yh_yjscpz')" @click="showVoucher(item)" >凭证预览</span>
            </div>
            <div class="ccyc">
              
            </div>
            <!-- 编辑，删除时候出现的保存，删除按钮 -->
            <div class="bank-item-header-caozuo" style="margin-right: 15px;" v-if="item.show || this.activeName == 'second'" >
              <el-tooltip content="保存" placement="top" v-if="$buttonStatus('zj_yh_yjqc')" effect="dark">
                <i class="iconfont icon-baocun" @click="handleSave(item)" style="color:var(--themeColor,#17a2b8)"></i>
              </el-tooltip>
            </div>
            <!-- 鼠标经过时出现的删除以及其他按钮 -->
            <div class="bank-item-header-caozuo" style="margin-right: 0;">
              <span v-if="item.from == 'collect'" class="ybStyle" style="font-size:11px;padding:0px;position: relative;top:-2px">采</span>
              <el-tooltip content="查看导入回单" placement="top" effect="dark" v-if="item.from == 'import' && item.pdf != ''">
                <el-button style="padding:0px;padding-bottom:6px" type="text" size="small" @click="checkBankPdf(item,'import')" >
                  <i class="iconfont icon-pdf" style="color: rgb(13, 197, 13);font-size: 16px;" ></i>
                </el-button>
              </el-tooltip>
              <el-tooltip content="查看电子回单" placement="top" effect="dark" v-if="item.from == 'collect'">
                <el-button style="padding:0px;padding-bottom:6px" type="text" size="small" @click="checkBankPdf(item,'collect')">
                  <i class="iconfont icon-pdf" style="color: rgb(13, 197, 13);font-size: 16px;"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip content="删除" placement="top" v-if="$buttonStatus('zj_yh_plsc')" effect="dark">
                <i class="iconfont icon-shanchu" @click="handleDeleteBig(item)" style="color:var(--themeColor,#17a2b8)"></i>
              </el-tooltip>
            </div>
          </section>
          <!-- 银行账期的选择 -->
          <!-- 一小条 -->
          <table class="item_box" cellspacing="0" width="100%">
            <tr v-for="(bankItem,index2) in item.items" :key="index2">
              <td :style="{width:(accountStatus != '人民币' && accountStatus != '' && accountStatus ? '10%':'15%')}">
                <div v-if="item.edit || this.activeName == 'second'" style="width:98%;margin: 0 auto">
                  <el-date-picker style="width:100%;" type="date" v-model="bankItem.transDate" :clearable="false" placeholder="选择日期" size="small"></el-date-picker>
                </div>
                <span v-else style="padding-left:10px;color:#212529;">{{ $parseTime(bankItem.transDate, "{y}-{m}-{d}") }}</span>
              </td>
              <td :style="{width:(accountStatus != '人民币' && accountStatus != '' && accountStatus ? '25%':'30%')}">
                <div v-if="item.edit || this.activeName == 'second'" style="width:98%;margin: 0 auto">
                  <div class="block">
                    <!-- <VirtualCascader :modelValue="['3462', '3462-57806550-0']" labelKey="name" valueKey="sign" childrenKey="items" /> -->
                    <!-- <ChooseBussiness :name="item.otherName" :direction="getDirection(item)" v-model:businessId="bankItem.businessTypeId" v-model:itemId="bankItem.subjectId" v-model:fzhsId="bankItem.fzhsId" v-model:szbz="bankItem.type" @success2="changeBusiness2" @success="changeBusiness(bankItem,item)" type="bank"></ChooseBussiness> -->
                    <business-type :name="item.otherName" :direction="getDirection(item)" v-model:businessId="bankItem.businessTypeId" v-model:itemId="bankItem.subjectId" v-model:fzhsId="bankItem.fzhsId" v-model:szbz="bankItem.type" @success2="changeBusiness2" @success="changeBusiness(bankItem,item)" type="bank"></business-type>
                  </div>
                </div>
                <div v-else>
                  <el-tooltip :content="bankItem.businessTypeName+'-'+bankItem.subjectName" placement="top" effect="dark">
                    <div class="ccyc" style="text-align: left;">
                      <span style="padding-left:10px;">{{bankItem.businessTypeName}}-</span>
                      <span>{{bankItem.subjectName}}</span>
                      <span v-if="bankItem.fzhsName">-{{bankItem.fzhsName}}</span>
                    </div>
                  </el-tooltip>
                  
                </div>
              </td>
              <td v-if="accountStatus != '人民币' && accountStatus != '' && accountStatus" style="width: 10%;">
                <div style="width:98%;margin: 0 auto">
                  <qzf-input v-if="item.edit || this.activeName == 'second'" size="small"  @blur="changeWb(bankItem)" v-model:num="bankItem.wbAmount"/>
                  <span v-else style="margin-left: 10px;color:#212529">{{ Math.abs(bankItem.wbAmount )}}</span>
                </div>
              </td>
              <td v-if="accountStatus != '人民币' && accountStatus != '' && accountStatus" style="width: 10%;">
                <div style="width:98%;margin: 0 auto">
                  <qzf-input v-if="item.edit || this.activeName == 'second'"  @blur="changeWb(bankItem)" size="small" v-model:num="bankItem.rate"/>
                  <span v-else style="margin-left: 10px;color:#212529">{{ Math.abs(bankItem.rate )}}</span>
                </div>
              </td>
              <td style="width: 10%;">
                <div style="width:98%;margin: 0 auto">
                  <qzf-input v-if="item.edit || this.activeName == 'second'"  size="small"  v-model:num="bankItem.amount"/>
                  <span v-else style="margin-left: 10px;color:#212529">{{ Math.abs(bankItem.amount )}}</span>
                </div>
              </td>
              <td :style="{width:(accountStatus != '人民币' && accountStatus != '' && accountStatus ? '10%':'15%')}">
                <div style="width:98%;margin: 0 auto">
                  <el-input v-if="item.edit || this.activeName == 'second'" size="small" type="text" v-model="bankItem.summary"/>
                  <div v-else style="margin-left: 10px;color:#212529;line-height: normal;">{{ bankItem.summary }}</div>
                </div>
              </td>
              <td :style="{width:(accountStatus != '人民币' && accountStatus != '' && accountStatus ? '15%':'20%')}">
                <el-tooltip :content="bankItem.bz" placement="top" effect="dark" :disabled="!bankItem.bz">
                  <div class="ccyc" style="text-align: left;">
                    <!-- <span style="padding-left:10px;">{{bankItem.bz}}</span> -->
                    <el-input v-if="item.edit || this.activeName == 'second'" size="small" type="text" v-model="bankItem.bz"/>
                  <div v-else style="margin-left: 10px;color:#212529;line-height: normal;">{{ bankItem.bz }}</div>
                  </div>
                </el-tooltip>
              </td>
              <td class="top_right_btns" style="width: 10%;">
                <!-- <el-tooltip
                  class="item"
                  effect="dark"
                  placement="top"
                  v-if="bankItem.bz"
                >
                  <template #content> <div v-html="bankItem.bz"></div></template>
                  <span class="xgmStyle" style="font-size:11px;cursor: pointer;padding:0px;position: relative;top:-2px">备注</span>
                  </el-tooltip> -->
                <el-tooltip content="新增" placement="top" v-if="$buttonStatus('zj_yh_tj')" effect="dark">
                  <i class="iconfont icon-jiahao" @click="handleAdd(item,bankItem,index2)"></i>
                </el-tooltip>
                <el-tooltip content="编辑" placement="top" v-if="$buttonStatus('zj_yh_yjqc')" effect="dark">
                  <i class="iconfont icon-bianji" @click="handleEdit(bankItem,item)"></i>
                </el-tooltip>
                <el-tooltip content="删除" placement="top" v-if="item.items.length > 1" effect="dark">
                  <i class="iconfont icon-shanchu" @click="handleDelete(item,bankItem,index2)" v-if="$buttonStatus('zj_yh_plsc')"></i>
                </el-tooltip>
              </td>
            </tr>
          </table>
        </section>
        </el-scrollbar>
        </div>
      <!-- </div> -->
    </div>
    
    <div>
      <div class="bottom_button" v-if="this.selects.length != 0">
        <i class="iconfont icon-gantanhao" style="color:#e6a23c;margin-right:5px;font-size:13px"></i>统计：&nbsp;{{ this.selects.length }}份 &nbsp;收入金额：{{ inData }} &nbsp; <span v-if="outData < 0">支出金额：{{ outData.substring(1) }}</span>
      </div>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </div>
    </el-tabs>
    
    <el-dialog :close-on-click-modal="false"
        v-model="dialogVisible"
        title="批量选择"
        width="30%"
        destroy-on-close
      >
      <el-radio-group style="margin-bottom: 15px;" size="small" v-model="bigBusiness.bType">
        <el-radio :label="1" size="small" border>批量选择</el-radio>
        <el-radio :label="2" size="small" border>批量创建</el-radio>
      </el-radio-group>
      <!-- <VirtualCascader :modelValue="['3462', '3462-57806550-0']" labelKey="name" valueKey="sign" childrenKey="items" /> -->
      <!-- <ChooseBussiness v-if="bigBusiness.bType == 1" :direction="singleItem" v-model:businessId="bigBusiness.businessTypeId"  v-model:businessTypeName="bigBusiness.summary" v-model:itemId="bigBusiness.subjectId" v-model:fzhsId="bigBusiness.fzhsId" type="bank"></ChooseBussiness> -->
      <!-- <ChooseBussiness v-if="bigBusiness.bType == 2" :add="true" :direction="singleItem" v-model:businessId="bigBusiness.businessTypeId"  v-model:businessTypeName="bigBusiness.summary" v-model:itemId="bigBusiness.subjectId" v-model:fzhsId="bigBusiness.fzhsId" type="bank"></ChooseBussiness> -->
      <business-type v-if="bigBusiness.bType == 1" :direction="singleItem" v-model:businessId="bigBusiness.businessTypeId"  v-model:businessTypeName="bigBusiness.summary" v-model:itemId="bigBusiness.subjectId" v-model:fzhsId="bigBusiness.fzhsId" type="bank"></business-type>
      <business-type v-if="bigBusiness.bType == 2" :add="true" :direction="singleItem" v-model:businessId="bigBusiness.businessTypeId"  v-model:businessTypeName="bigBusiness.summary" v-model:itemId="bigBusiness.subjectId" v-model:fzhsId="bigBusiness.fzhsId" type="bank"></business-type>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small">取 消</el-button>
          <el-button v-if="bigBusiness.bType == 1" type="primary" @click="changebigBusiness()" size="small">确 定</el-button>
          <el-button v-if="bigBusiness.bType == 2" type="primary" :loading="isLoading" @click="changebigBusinessCj()" size="small">批量创建</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 结算 选择银行账户 -->
    <el-dialog :close-on-click-modal="false"
      v-model="selectBankDialog"
      title="批量选择"
      width="30%"
      destroy-on-close
    >
      <span style="margin-left:10px;">
        银行账户：
      </span>
      <el-select placeholder="请选择" v-model="bankNameId" @change="changeBank" size="mini">
        <el-option v-for="(item,index) in bankList" :key="index" :label="item.name" :value="item.id" size="mini">
        </el-option>
      </el-select>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="selectBankDialog=false;changeBank();" size="small">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 选择电子回单查看方式 -->
    <el-dialog
      v-model="checkPaperTypedialogVisible"
      title="请选择纸张类型"
      width="30%"

    >
      <el-radio-group v-model="this.paperType">
        <el-radio label="A5">A5纸张</el-radio>
        <el-radio label="A4">A4纸张</el-radio>
      </el-radio-group>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="checkPaperTypedialogVisible = false" size="small">取消</el-button>
          <el-button type="primary" @click="makeSureCkeck" size="small">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 查看余额 -->
    <el-dialog
      v-model="amountVisible"
      title="查看余额"
      width="50%"
    >
      <table class="content content_head" cellspacing="0" width="100%" >
        <tr class="top_bg center">
          <td style="width:15%;">方式</td>
          <td style="width:35%;">流水号</td>
          <td style="width:25%;">最后业务发生时间</td>
          <td style="width:25%;">{{info.method == 'import' ? '导入' : '采集'}}余额</td>
        </tr>
        <tr class="center">
          <td >{{info.method == 'import' ? '导入' : '采集'}}</td>
          <td>{{ info.lsh }}</td>
          <td>{{ info.sj }}</td>
          <td>{{ info.amount }}</td>
        </tr>
      </table>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="amountVisible = false" size="small" type="primary">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <qzf-import-back :hideButton="true" ref="importBck" @success="getList()" @success2="otherBankDialog" from="银行" :id="this.bankNameId"></qzf-import-back>
    <qzf-import-back :hideButton="true" ref="importBckPdf" @success="getList()" from="银行PDF" :id="this.bankNameId" :comId="collectComId" :bankName="bankName"></qzf-import-back>
    <bankAuth ref="bankAuth" @success="init('2')"></bankAuth>
    <voucher-show ref="voucherShow" from="6" @success="getList"></voucher-show>
    <otherBank :bankName='this.bankName' :bankId='this.bankNameId' ref="bankTy" @success="getList"></otherBank>

    <oneKeyVoucherHabbit ref="oneKeyVoucherHabbit" @success=getList></oneKeyVoucherHabbit>

  <accreditBank ref="accreditBank" :bankName="bankName" :bankId="bankId" @success="getList" ></accreditBank>

  <autoSetting ref="autoSetting" @success="getNewList" @success2="changeBtnLoading"></autoSetting>


  </div>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogPdfVisible"
    width="90%"
    title="影像预览"
  >
    <iframe
      style="position: relative"
      :src="bankUrl"
      frameborder="0"
      id="mainiframe"
      width="100%"
      :height="contentStyleObj2"
      border="0"
    >
    </iframe>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" size="small" @click="dialogPdfVisible = false">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 流水排序 -->
  <el-dialog
    v-model="lsDialogVisible"
    title="选择排序方式"
    width="30%"
    :before-close="handleClose"
  >
  <div style="margin-left:6px;margin-bottom:20px">
    <span style="position: relative;top: -2px;font-size: 15px;margin-right:15px">排序方式选择 :</span>
    <el-radio-group v-model="listQuery.sortType" class="ml-4" size="large">
      <el-radio :label="1" size="large">升序</el-radio>
      <el-radio :label="2" size="large">降序</el-radio>
    </el-radio-group>
  </div>
    <el-select v-model="listQuery.sortField" class="m-2" placeholder="选择排序方式">
      <el-option
        v-for="item in options2"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        style="width: 270px;"
      />
    </el-select>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="lsDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleSureSort">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { bankNameList , bankList , saveBank , delBank ,getIcbcDz ,bankPdf ,clearBank } from "@/api/bank"
import { wbList } from "@/api/subject"
import { inOutBankList } from '@/api/export'
import { getPeriodTime } from "@/utils/fun";
import otherBank from "@/components/import/son/otherBank"
import { bcCreateCorpAndSubject } from "@/api/invoice.js"
import oneKeyVoucherHabbit from "../../../bookkeeps/components/oneKeyVoucherHabbit.vue"
import accreditBank from "../../../batch/components/accreditBank.vue";
import autoSetting from "../../../autoHome/components/autoSetting.vue";
import { getAutoBcStatus } from "@/api/invoice.js";
import bankAuth from '@/components/bankAuth'
// import VirtualCascader from './VirtualCascader'
// import ChooseBussiness from './ChooseBussiness.vue'

function deepClone(obj) {
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        //判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === "object") {
          objClone[key] = deepClone(obj[key]);
        } else {
          //如果不是，简单复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}

export default {
  name:'bank',
  components:{
    otherBank,
    oneKeyVoucherHabbit,
    accreditBank,
    autoSetting,
    bankAuth,
    // VirtualCascader,
    // ChooseBussiness,
  },
  data() {
    return {
      lsDialogVisible:false,
      options2:[
        {
          value:'1',
          label:"按创建时间"
        },
        {
          value:'2',
          label:"按交易日期+先收入后支出"
        },
      ],
      comId: this.$store.getters["user/comInfo"].comId * 1,
      orgId: this.$store.getters["user/user"].orgId,
      activeName:'first',
      bankList:[],
      accountStatus:'',
      otherName:'',
      listQuery:{
        page:1,
        limit:20,
        type: 0,
        bankId:0,
        typeAmount:null,  //收入支出
        sortType:1,
        sortField:'1',//排序 1：创建时间 2：创建时间+交易日期+先收入后支出
        bz:'',
      },
      total:0,
      shows: true,
      contentStyleObj:{}, //高度变化
      bankNameId:'',
      bankName:'',
      list:[],
      wbOptions:[],
      huilv:1,
      deleteLoding:false,
      bankAmount: "",
      bankAmountCount: "",
      bankAmountOut: "",
      bankAmountIn: "",
      bankEndAmount:"",
      dialogVisible:false,
      singleItem:'',
      bigBusiness: {
        businessTypeId:0,
        summary:'',
        subjectId:0,
        fzhsId:0,
        bType: 1,
      },
      isLoading:false,
      checkPaperTypedialogVisible:false,
      paperType:'',
      rowId:'',
      selects: [],
      changeSummary:'',
      collectComId:this.$store.getters['user/comInfo'].comId*1,
      collectPeriod:this.$store.getters['user/comInfo'].period,
      assignXybh:'',
      checkType:'',
      rowData:{},
      inData:0,
      outData:0,
      descStatus:false,
      buttonLoading:false,
      pageStatus: true,
      autoBcStatus:false,
      autoBcType:"",
      loading1:false,
      info:{},
      amountVisible:false,
      bankUrl:"",
      dialogPdfVisible:false,
      contentStyleObj2:{},
      merge:""
      // fromInput: null, // 非空表示是从发票录入的结算而来, 注意:一定要在适当的时候重置为null (其它跳转至此页面, 结算产生的记录保存成功之后,...)
      // selectBankDialog:false
    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created() {
    this.contentStyleObj=this.$getHeight(328)
    this.contentStyleObj2 = this.$getHeight(230)
    this.init()
    if(localStorage.getItem('bankSortType') || localStorage.getItem('bankSortField')){
      this.listQuery.sortType = localStorage.getItem('bankSortType')*1
      this.listQuery.sortField = localStorage.getItem('bankSortField')
    }
  },
  mounted(){
    this.getInfo()
    this.initBus()
    this.initBcStatus()
  },
  computed:{
    isAllSelected() {
      return this.list.every(el => {
        return el.checked;
      });
    },
    currentBank() {
      let bank = {};
      this.bankList.some(item => {
        if (item.id == this.bankId) {
          bank = item;
        }
      });
      return bank;
    },
    // 选中的id
    checkedGoodIds() {
      let filterArr = this.list.filter((el,i) => {
        el.i = i
        return el.checked;
      });
      return filterArr.map((el,i) => {
        return el.id + "##" + el.i;
      });
    },
    options(){
        let datas = this.$store.getters["commons/businessType"].bank
        return datas
    },
  },
  methods:{
    getInfo(){
      getAutoBcStatus({type:'bank'}).then(res=>{
        this.autoBcStatus = res.data.data.autoBc
        this.autoBcType = res.data.data.autoBcType
      })
    },
    initBcStatus(){
      this.$bus.off("autoBcStatusUpdate")
      this.$bus.on("autoBcStatusUpdate", (val) => {
        getAutoBcStatus({type:'bank'}).then(res=>{
          this.autoBcStatus = res.data.data.autoBc
          this.autoBcType = res.data.data.autoBcType
        })
      });
    },
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(!this.pageStatus){
          return
        }
        if(this.list.length == 0){
          return
        }
        if(this.autoBcStatus){
          getAutoBcStatus({type:'bank'}).then(res => {
            this.autoBcStatus = res.data.data.autoBc
            this.autoBcType = res.data.data.autoBcType
            if(!this.autoBcStatus){
              this.$qzfMessage('自动补充完成！')
              this.getNewList()
              this.$bus.emit('autoBcStatusUpdate')
            }
          })
        }
      });
    },
    getDirection(item){
      if(item.id == 0){
        return ""
      }
      if(item.amount < 0){
        return "out"
      }else if(item.amount > 0){
        return "in"
      }else{
        return ""
      }
    },
    // 签约的
    showAgreement(){
      if(this.$checkSettleStatus()) return
      this.$refs.accreditBank.init(0,'',this.listQuery)
    },
    init(a){
      bankNameList({}).then(res=>{
        if(a == '2'){
          if(res.data.data.list.length != 0){
            let lastListOne = res.data.data.list[res.data.data.list.length-1]
            this.bankList = res.data.data.list
            this.bankName = lastListOne.name
            this.bankNameId = lastListOne.id
            this.listQuery.bankId = lastListOne.id
            this.assignXybh = lastListOne.xybh
            this.getList()
            this.accountStatus = lastListOne.currency
            if(lastListOne.currency == "美元"){
              this.wbOptions.map(item=>{
                if(item.code == "美元"){
                  this.huilv = item.rate
                }
              })
            }
            if(lastListOne.currency == "欧元"){
              this.wbOptions.map(item=>{
                if(item.code == "欧元"){
                  this.huilv = item.rate
                }
              })
            }
            if(lastListOne.currency == "港币"){
              this.wbOptions.map(item=>{
                if(item.code == "港币"){
                  this.huilv = item.rate
                }
              })
            }
            if(lastListOne.currency == "日元"){
              this.wbOptions.map(item=>{
                if(item.code == "日元"){
                  this.huilv = item.rate
                }
              })
            }
            if(lastListOne.currency == "英镑"){
              this.wbOptions.map(item=>{
                if(item.code == "英镑"){
                  this.huilv = item.rate
                }
              })
            }
            if(lastListOne.wbRate != ""){
              this.huilv = Number(lastListOne.wbRate)
              return
            }
            if(this.$buttonStatus('zj_yh_xzzh')){
              this.bankList.unshift({ name: "新增账户+", id: "add" });
            }
          }else{
            if(this.$buttonStatus('zj_yh_xzzh')){
              this.bankList.unshift({ name: "新增账户+", id: "add" });
            }
          }
        }else{
          if(res.data.data.list.length !=0){
            this.bankList = res.data.data.list
            this.accountStatus = this.bankList[0].currency
            this.bankNameId = res.data.data.list[0].id
            this.bankName = res.data.data.list[0].name
            this.listQuery.bankId = res.data.data.list[0].id
            this.assignXybh = res.data.data.list[0].xybh

            this.changeBank(res.data.data.list[0].id)
            // if (this.fromInput && res.data.data.list.length != 1) {
            // // 多个或0个银行账户 需手工选择或增加
            //   this.selectBankDialog = true;
            // }
            if(this.$buttonStatus('zj_yh_xzzh')){
              this.bankList.unshift({ name: "新增账户+", id: "add" });
            }
          }else{
            // if (this.fromInput && res.data.data.list.length == 0) {
            // // 多个或0个银行账户 需手工选择或增加
            //   this.selectBankDialog = true;
            // }
            if(this.$buttonStatus('zj_yh_xzzh')){
              this.bankList.unshift({ name: "新增账户+", id: "add" });
            }
          }
        }
      })
      wbList({}).then(res=>{
        this.wbOptions = res.data.data.list
      })
      // this.fromInput = this.$route.params.settleType
    },
    getList(){
      // //console.log(this.bankNameId);
      // 签约完之后还是这个银行的刷新list
      if(this.bankNameId){
        this.listQuery.bankId = this.bankNameId
      }
      this.isLoading = true
      bankList(this.listQuery).then(res=>{
        this.isLoading = false
        this.info = res.data.data.cjBankEndAmount
        this.bankAmountIn = res.data.data.in
        this.bankAmountOut = res.data.data.out
        this.bankAmount = res.data.data.endAmount
        this.bankEndAmount = res.data.data.bankEndAmount
        this.merge = res.data.data.merge
        this.selects = []
        this.list = res.data.data.list ? res.data.data.list : []
        this.list.map(item => {
          // 每一小条
          item.items.map(v => {
            if (!v.businessTypeId) {
              item.edit = true;
              item.show = true;
            }
          });
        });
        this.total = res.data.data.total
      })
    },
    // 选择银行
    changeBank(e){
      if(e == "add"){
        if(this.$checkSettleStatus()) return
        let param = {
          comId:this.comId,
          comName:""
        }
        this.$refs.bankAuth.init(param)
        this.bankNameId = ""
        this.listQuery.bankId = 0
        this.getList()
        return
      }
      this.bankList.map(v=>{
        if(v.id == e){
          this.accountStatus = v.currency
          this.bankNameId = e
          this.listQuery.bankId = e
          this.bankName = v.name
          this.assignXybh = v.xybh
          if(v.wbRate != ""){
            this.huilv = Number(v.wbRate)
            return
          }
          if(v.currency == "美元"){
            this.wbOptions.map(item=>{
              if(item.code == "美元"){
                this.huilv = item.rate
              }
            })
          }
          if(v.currency == "欧元"){
            this.wbOptions.map(item=>{
              if(item.code == "欧元"){
                this.huilv = item.rate
              }
            })
          }
          if(v.currency == "港币"){
            this.wbOptions.map(item=>{
              if(item.code == "港币"){
                this.huilv = item.rate
              }
            })
          }
          if(v.currency == "日元"){
            this.wbOptions.map(item=>{
              if(item.code == "日元"){
                this.huilv = item.rate
              }
            })
          }
          if(v.currency == "英镑"){
            this.wbOptions.map(item=>{
              if(item.code == "英镑"){
                this.huilv = item.rate
              }
            })
          }
        }
      })
      // if(this.fromInput){
      //   this.getList()
      //   this.getJsList()
      // }else{
        this.getList()
      // }
    },
    // 添加银行
    handleAddBig(){
      if(this.$checkSettleStatus()) return
      if (!this.bankNameId) {
        this.$message({
          message: "请选择银行",
          type: "error"
        });
        return;
      }
      this.list.unshift({
        bankNameId: this.bankNameId,
        bankName: this.bankName,
        type: 1,
        edit: true,
        show: true,
        from:'manual',
        otherName:this.otherName,
        items: [
          {
            transDate: getPeriodTime(),
            amount: 0,
            wb: this.accountStatus,
            wbAmount: 0,
            rate: this.huilv,
            summary: ""
          }
        ]
      });
    },
    changeWb(row){
      row.wbAmount = Number(row.wbAmount)
      row.amount = Number((row.wbAmount*1 * row.rate).toFixed(2))
    },
    // 添加小条
    handleAdd(item, row, index){
      if(this.$checkSettleStatus()) return
      item.show = true;
      item.edit = true;
      let clone = deepClone(row);
      clone.transDate = row.transDate;
      delete clone.id;
      clone.summary = ""
      item.items.splice(index + 1, 0, clone);
    },
    // 编辑小条
    handleEdit(row, item) {
      if(this.$checkSettleStatus()) return
      item.show = true;
      item.edit = true;
    },
    // 删除小条
    handleDelete(item, row, index) {
      if(this.$checkSettleStatus()) return
      item.show = true;
      item.edit = true;
      item.items.splice(index, 1);
    },
    // 保存
    handleSave(item) { 
      if(this.$checkSettleStatus()) return
      let total = item.items.reduce((acc, v) => {
        return v.type === 2 ? acc - v.amount : acc + v.amount;
      }, 0);
      total = Number(total.toFixed(2));
      let confirmationMessage = '';
      if (item.voucherId) {
        confirmationMessage = item.amount !== total && item.id ? '该笔流水已生成凭证且金额与明细金额不一致，是否保存？' : '该笔流水已生成凭证，是否保存？';
      }else{
        confirmationMessage = item.amount !== total && item.id ? '金额与明细金额不一致，是否保存？' : ''
      }
      if(confirmationMessage){
        this.$confirm(confirmationMessage, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.saveSure(item)
        })
      }else{
        this.saveSure(item)
      }
    
    },
    saveSure(item){
      saveBank([item]).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.$bus.emit('voucherUpdate')
          this.getList()
        }
      })
    },
    // 删除银行记录
    handleDeleteBig(item) {
      if(this.$checkSettleStatus()) return
      this.$confirm("确认删除该条数据？", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:'el-icon-delete'

      }).then(() => {
        delBank([{id: Number(item.id)}]).then(res => {
          if(res.data.msg == 'success'){
            this.$qzfMessage("删除成功")
            this.$bus.emit('voucherUpdate')
            this.changeBank(String(this.bankId));
          }
        });
      });
    },
    handleClick(tab) {
      if(tab.props.name == 'second'){
        this.listQuery.type = 2
      }else if (tab.props.name == 'third'){
        this.listQuery.type = 1
      }else {
        this.listQuery.type = 0
      }
      this.getList()
    },
    showVoucher(item){
      this.$refs.voucherShow.getList(item)
    },
    getHandlePingzheng(voucherId) {
      this.$store.dispatch('commons/getSubjects', this.comId)
      this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
      this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher"
      });
    },
    otherBankDialog(e,type){
      this.$refs.bankTy.dialogFormVisible = true
      this.$refs.bankTy.init(e,type)
    },
    // 全选、全不选
    allSelect() {
      let checked = true;
      // 全选
      if (this.isAllSelected) {
        checked = false;
      }
      this.list = this.list.map(el => {
        el.checked = checked;
        return el;
      });
      this.screenChecked()
    },
    singleSelect(row, index) {
      row.checked = !row.checked;
      this.list.splice(index, 1, row);
      this.screenChecked()
    },
    screenChecked(){
      this.selects = this.list.filter(v=>{return v.checked})
      if(this.selects.length != 0){
        this.inData = 0;
        this.outData = 0;
        this.selects.map(v=>{
          if(v.amount < 0){
            this.outData += v.amount
          }else{
            this.inData += v.amount
          }
        })
        this.inData = (this.inData).toFixed(2)
        this.outData = (this.outData).toFixed(2)
      }
    },
    handleDeleteAll(){
      let data = [];
      this.checkedGoodIds.map(item => {
        data.push({ id: item.split('##')[0]*1});
      });
      if (data.length < 1) {
        this.$qzfMessage("请至少选择一条数据",1)
        return;
      }
      this.$confirm("确认删除"+data.length+"条数据？", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:'el-icon-delete'
      }).then(() => {
        this.isLoading = true
        delBank(data).then(res => {
        this.isLoading = false

          if(res.data.msg == 'success'){
            this.$qzfMessage("删除成功")
            this.$bus.emit('voucherUpdate')
            this.changeBank(String(this.bankId));
          }
        });
      });
    },
    handleCommand() {
      if(this.$checkSettleStatus()) return
      if(this.selects.length == 0){
        this.$qzfMessage("请选择至少一条数据",1)
        return
      }
      let invoiceArray = []
      this.selects.map(v => {
        invoiceArray.push(v.id)
      })
      let invoiceTemp ={
        invoiceArray:invoiceArray,
        sortField:this.listQuery.sortField,
        sortType:this.listQuery.sortType
      }
      this.$refs.oneKeyVoucherHabbit.getList('bank',invoiceTemp)
    },
    // 排序
    sort(){
      this.lsDialogVisible = true
      // if(val == 'je'){
      //   this.listQuery.sortField = '1'
      // }else if(val == 'sr'){
      //   this.listQuery.sortField = '2'
      // }else if(val == 'sj'){
      //   this.listQuery.sortField = '3'
      // }
      // this.getList()
    },
    handleSureSort(){
      localStorage.setItem('bankSortType',this.listQuery.sortType)
      localStorage.setItem('bankSortField',this.listQuery.sortField)
      this.listQuery.orderBy = ''
      this.lsDialogVisible = false
      this.getList()
    },
    //导出
    daochu(){
      let param = {
        query:this.listQuery,
        ids:[]
      }
      this.selects.map(v => {
        param.ids.push(v.id)
      })
      inOutBankList(param).then(res=>{
        if(res.data.msg == 'success'){
          window.open(res.data.data.url)
        }
      })
    },
    // 采集
    collectBank(){
      if(this.assignXybh == ""){
        this.$qzfMessage("请先进行授权",2)
        return
      }
      this.isLoading = true
      let param = {
        taskName: 'cj-bank',
        comIds:[this.$store.getters['user/comInfo'].comId*1],
        period:this.$store.getters['user/comInfo'].period,
        cond:{
          bankId:this.bankNameId
        }
      }
      getIcbcDz(param).then(res => {
        if(res.data.msg == "success"){
          this.$qzfMessage('任务已发起',3)
          this.getList();
        }
      })
    },
    openCom(){
      if(this.$checkSettleStatus()) return
      if (!this.bankNameId) {
        this.$message({
          message: "请选择银行",
          type: "error"
        });
        return;
      }
      this.$refs.importBck.handImport()
    },
    openComPdf(){
      if(this.$checkSettleStatus()) return
      if (!this.bankNameId) {
        this.$message({
          message: "请选择银行",
          type: "error"
        });
        return;
      }
      this.$refs.importBckPdf.handImport()
    },
    allSave(){
      let str = ''
      this.list.map(v=>{
        if(v.voucherId){
          str = '检测到您有关联凭证，一键全存会同时清除凭证信息，是否继续？'
          return
        }
      })
      if(str){
        this.$confirm(str, "提示", {
          confirmButtonText: " 确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.allSaveSure()
        });
      }else{
        this.allSaveSure()
      }
    },
    allSaveSure(){
      this.isLoading = true
      saveBank(this.list).then(res=>{
        this.isLoading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.$bus.emit('voucherUpdate')

          this.getList()
        }
      })
    },
    //批量补充
    handleCzmuch() {
      let data = [];
      this.checkedGoodIds.map(item => {
        data.push({ id: item.split('##')[1]*1});
      });
      if (data.length < 1) {
        this.$qzfMessage("请至少选择一条数据",1)
        return;
      }
      let zheng = false
      let fu = false
      let tiaoShu = false
      data.map(v=>{
        if(this.list[v.id].amount < 0){
          fu = true
        }else if(this.list[v.id].amount > 0){
          zheng = true
        }else if(!this.list[v.id].id){
          tiaoShu = true
        }
      })
      if(fu && zheng){
        this.$qzfMessage("请选择一个方向进行批量补充!",1)
        return;
      }
      if(tiaoShu){
        this.$qzfMessage("请保存之后再补充!",1)
        return;
      }
      if(fu){
        this.singleItem = 'out'
      }else{
        this.singleItem = 'in'
      }
      this.bcList = data
      this.bigBusiness = {
        businessTypeId:0,
        summary:'',
        subjectId:0,
        fzhsId:0,
        bType: 1,
      }
      let str = ''
      this.selects.map(v=>{
        if(v.voucherId){
          str = '检测到您有关联凭证，重新补充会同时清除凭证信息，是否继续？'
          return
        }
      })
      if(str){
        this.$confirm(str, "提示", {
          confirmButtonText: " 确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.dialogVisible = true
          this.list.map(v=>{
            v.show = true
            v.edit = true
          })
        });
      }else{
        this.dialogVisible = true
        this.list.map(v=>{
          v.show = true
          v.edit = true
        })
      }
    },
    // 批量创建业务类型
    changebigBusiness(){
      this.bcList.map(v=>{
        this.list[v.id].items.map(item=>{
          item.businessTypeId = this.bigBusiness.businessTypeId
          item.subjectId = this.bigBusiness.subjectId
          item.fzhsId = this.bigBusiness.fzhsId
          // if(!item.summary){
            item.summary = this.bigBusiness.summary
          // }
        })
      })
      this.saveGx()
      // this.$qzfMessage("补充完成")
      this.dialogVisible = false
    },
    changebigBusinessCj(){
      let param = {
        subjectId: this.bigBusiness.subjectId,
        fzhsId: this.bigBusiness.fzhsId,
        items: [],
      }
      this.bcList.map(v=>{
        if(this.list[v.id].otherName){
          param.items.push({
            corpName: this.list[v.id].otherName,
            Id: this.list[v.id].id
          })
        }
      })
      this.isLoading = true
      bcCreateCorpAndSubject(param).then(res=>{
        this.isLoading = false
        this.dialogVisible = false
        if(res.data.msg != "success"){
          return
        }
        this.$store.dispatch("commons/getSubjects")
        this.$store.dispatch("commons/getBusinessType")
        let list = res.data.data.list
        list.map(l=>{
          this.bcList.map(v=>{
            if(this.list[v.id].id == l.id){
              this.list[v.id].items.map(item=>{
                item.businessTypeId = this.bigBusiness.businessTypeId
                item.subjectId = l.subjectId
                item.fzhsId = l.fzhsId
                // if(!item.summary){
                  item.summary = this.bigBusiness.summary
                // }
              })
            }
          })
        })
        this.saveGx()
      })
    },
    saveGx(){
      this.isLoading = true
      saveBank(this.selects).then(res=>{
      this.isLoading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.$bus.emit('voucherUpdate')
          this.getList()
        }
      })
    },
    checkBankPdf(row,type){
      // this.checkType = type
      // this.checkPaperTypedialogVisible = true
      // this.rowData = Object.assign({},row)
      // this.rowId = row.id
      if(type == 'collect'){
        let param = {
          id:row.id,
          paperType:'A5'
        }
        bankPdf(param).then(res => {
          if(res.data.msg == "success"){
            this.bankUrl = res.data.data.url
            this.dialogPdfVisible = true
          }
        })
      }else{
        this.bankUrl = row.pdfA5?.startsWith('https://') ? row.pdfA5 : 'https://file.listensoft.net' + row.pdfA5
        this.dialogPdfVisible = true
      }
    },
    makeSureCkeck(){
      if(this.checkType == 'collect'){
        let param = {
          id:this.rowId,
          paperType:this.paperType
        }
        bankPdf(param).then(res => {
          if(res.data.msg == "success"){
            this.checkPaperTypedialogVisible = false
            window.open(res.data.data.url)
          }
        })
      }else{
        this.checkPaperTypedialogVisible = false
        if(this.paperType == 'A4'){
          if(this.rowData.pdf.match(',')){
            let arr = this.rowData.pdf.split(',')
            arr.forEach(element => {
              if(element){
                window.open('https://file.listensoft.net'+element,'_blank')
              }
            });
          }else{
            window.open('https://file.listensoft.net' + this.rowData.pdf)
          }
        }else{
          if(this.rowData.pdfA5.match(',')){
            let arr = this.rowData.pdfA5.split(',')
            arr.forEach(element => {
              if(element){
                window.open('https://file.listensoft.net'+element,'_blank')
              }
            });
          }else{
            window.open('https://file.listensoft.net' + this.rowData.pdfA5)
          }
        }
      }
    },
    // 有备注的时候
    changeBusiness(val,item){
      // if(!val.summary || !this.merge.match('按摘要生成')){
        val.summary = this.changeSummary
      // }
    },
    changeBusiness2(val){
      // return
      this.changeSummary = val
    },
    //重置
    cancel(){
      let originLimit = this.listQuery.limit
      let originType = this.listQuery.type
      this.listQuery= {
        page: 1,
        limit:originLimit,
        type: originType,
        typeAmount:null, //收入支出
        pzzt:0,
        bz:'',
      }
      this.getList()
    },
    //排序
    sortChange(){
      this.descStatus = !this.descStatus
      if(this.descStatus){
        this.listQuery.orderBy = 'date asc'
      }else{
        this.listQuery.orderBy = 'date desc'
      }
      this.getList()
    },
    //自动补充
    bankSetting(){
      if(this.$checkSettleStatus()) return
      let ids = []
      this.selects.map(v => {
        ids.push(v.id)
      })
      this.$refs.autoSetting.init([this.comId],'bank',ids)
    },
    changeBtnLoading(){
      this.getInfo()
      this.loading1 = true
    },
    getNewList(){
      this.loading1 = false
      this.getList()
      this.getInfo()
      this.$store.dispatch("commons/getSubjects")
      this.$store.dispatch("commons/getBusinessType")
    },
    checkAmount(){
      this.amountVisible = true
    },
    //清除补充信息
    clearInfo(){
      if(this.$checkSettleStatus()) return
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let str = ''
      this.selects.map(v=>{
        if(v.voucherId){
          str = '检测到您有关联凭证，清除补充信息会同时清除凭证信息，'
          return
        }
      })
      this.$confirm(str+"是否确认清除？", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(()=>{
        this.loading1 = true
        let delSelects = []
        this.selects.map(v=>{
          delSelects.push({
            id:v.id
          })
        })
        clearBank(delSelects).then(res=>{
          this.loading1 = false
          if(res.data.msg == "success"){
            this.$qzfMessage("清除成功")
            this.getList()
          }
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
:deep(.el-input__inner){
  font-size: 12px;
}
.bank-item-container {
  margin-bottom: 10px;
  border: 1px solid #b9b9b9;
  border-bottom: 0 !important;
}
.bank-item-header {
  width: 100%;
  background: var(--themeColorLeftMenu,#ecf7f9);
  box-sizing: border-box;
  padding-left: 15px;
  height: 36px;
  line-height: 36px;
  font-size: 13px;
  // display: flex;
  position: relative;
  border-bottom: 1px solid #b9b9b9;
  > div {
    margin-right: 30px;
    display: inline-block;
  }
  .item-input {
    position: absolute;
    left: 5px;
    zoom: 110%;
    top: 50%;
    transform: translateY(-50%);
  }
}
.dfyhm{
  cursor: pointer;
  border: 1px solid #b9b9b9;
  border-radius: 2px;
  outline: none;
  width: 250px;
  line-height: 20px;
  margin-top: 6px;
  font-size: 12px;
}
.bank-item-header-caozuo {
  float: right;
 
  i {
    margin: 0 5px;
    cursor: pointer;
    // color: var(--themeColor,#17a2b8);
    font-size: 17px;
    line-height: 20px;
  }
}
.item_box{
  width: 100%;
  font-size: 12px;
  tr{
    width: 100%;
    display: flex;
    box-sizing: border-box;
    td{
      // flex: 1;
      line-height: 36px;
      border-color: #b9b9b9!important;
      border-bottom: 1px solid #b9b9b9;
      border-right: 1px solid #b9b9b9;
      &:last-child{
        border-right: none;
      }
    }
  }
}
.top_right_btns {
  float: right;
  // width: 22%;
  // margin-right: 15px;
  text-align: center;
  i {
    margin: 0 5px;
    cursor: pointer;
    color: var(--themeColor,#17a2b8);
    font-size: 17px;
    line-height: 24px;
  }
}
.bank-table-header {
  background: var(--themeColor,#17a2b8);
  position: relative;
  border: 1px solid #b9b9b9;
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  height: 36px;
  line-height: 34px;
  font-size: 13px;
  > div {
    border-right: 1px solid #b9b9b9;
    text-align: center;
    // flex: 1;
  }
  > div:last-child{
    border-right: 0;
  }
  input {
    position: absolute;

    zoom: 120%;
    left: 5px;
    top: 45%;
    transform: translateY(-50%) !important;
  }
}
.table_height{
  overflow-y: overlay;
  overflow-x:hidden;
}
.ccyc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  color: #212529;
}
.top_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  // .left_box{
  //   float: left;
  // }
  // .right_box {
  //   // float: right;
  //   text-align: right;
  // }
}
.opera_icon{
  cursor: pointer;
  font-size: 14px;
  top: 2px;
}
.hint{
  position: absolute;
  font-size: 13px;
  color: var(--themeColor, #17a2b8);
  top: 12px;
  left: 233px;
  z-index: 2;
}
.check{
  position: absolute;
  font-size: 13px;
  color: var(--themeColor, #17a2b8);
  top: 12px;
  left: 530px;
  z-index: 2;
  cursor: pointer;
}

.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  }
  .center{
    text-align: center;
  }
}
</style>